import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Grid, Typography } from '@mui/material';
import isEmail from "validator/lib/isEmail"
import { useDispatch } from 'react-redux';
import { POST } from '../../helpers/http';
import { loginUser } from '../../Redux/slices/userSlice';

const InputForm = (props) => {
    const defaultStateValues = {
        email: "",
        password: "",
    }
    const [inputs, setInputs] = useState(defaultStateValues);
    const [errors, setErrors] = useState(defaultStateValues)
    const [remoteError, setRemoteError] = useState('');
    const dispatch = useDispatch()

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        px: { sx: 1, sm: 2, md: 3 },
    };

    const validateFields = () => {
        const newErrors = { ...errors }
        if (!inputs.email) newErrors.email = "Email is Required"
        else if (!isEmail(inputs.email)) newErrors.email = "Invalid Email Address"
        else if (inputs.email.length > 128) newErrors.email = "Email length should not exceed 128 characters"

        if (!inputs.password) newErrors.password = "Password is Required"

        if (Object.values(newErrors).join(",").length <= 4) {
            return true
        } else {
            console.log({ newErrors })
            setErrors(newErrors)
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setRemoteError("")
        if (validateFields()) {
            const api = process.env.REACT_APP_URL + 'user/login'

            POST(api, { ...inputs })
                .then((response) => {
                    const result = response?.data
                    console.log(result)
                    localStorage.setItem('token', result.token)
                    // handleLogin()
                    dispatch(loginUser())
                    // dispatch(asyncGetUser())
                    props.history.push('/')
                })
                .catch((error) => {
                    const status = error?.response?.status
                    if (status === 401) {
                        setRemoteError('Invalid Email or Password')
                    }
                });
        }
    }


    const textProps = { sx: { height: '5vh', fontSize: '1.5vh' } }


    const handleChange = (e) => {
        if (remoteError) setRemoteError("")
        const name = e.target.name
        const value = name === 'role' ? e.target.value.toLowerCase() : e.target.value
        const newInputs = { ...inputs }
        const newErrors = { ...errors }
        newInputs[name] = value
        newErrors[name] = ""
        setInputs(newInputs)
        setErrors(newErrors)
    };



    return (
        <Box
            component="form"
            noValidate
            sx={formStyle}
            onSubmit={handleSubmit}
        >
            <TextField
                focused
                name="email"
                placeholder="Email ID"
                required
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email}
                value={inputs.email}
                onChange={handleChange}
                size={"small"}
                inputProps={textProps}
                sx={{ pb: '2vh' }}
            />
            <TextField
                name="password"
                focused
                placeholder="Password"
                type="password"
                required
                error={Boolean(errors.password)}
                helperText={errors.password}
                value={inputs.password}
                onChange={handleChange}
                size={"small"}
                inputProps={textProps}
                sx={{ mb: '5vh' }}
            />

            <Grid container pb={3}>
                <Grid item xs={12} textAlign="left" fontSize={"small"} color={"red"} >
                    {remoteError}
                </Grid>
            </Grid>
            <Box >
                <Box display="flex" justifyContent={"center"} sx={{ pb: '2vh' }}>
                    <Button sx={{ backgroundColor: '#FF6520', borderRadius: '8px', width: "100%", fontFamily: 'Roboto', height: '50px', fontSize: '1.5vh' }} size="small" variant="contained" type="submit">
                        {'Login'}
                    </Button>

                </Box>
                <Box display='flex' justifyContent={'center'} alignItems='center'>
                    <Typography sx={{ color: '#212121', fontSize: '12px' }}>Powered By</Typography>
                </Box>
                <Box display='flex' justifyContent='center'>
                    <img src="https://nhance.ai/images/nhance%20logo%202.png" width='80px' alt="logo" />
                </Box>
            </Box>

        </Box>
    );
};

export default InputForm;
