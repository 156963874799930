import React, { useState, useEffect } from 'react'
import { Tab, Box, Tabs, Divider } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const TabComp = (props) => {

    const { tabsList } = props
    let index = 0
    const location = useLocation()
    const history = useHistory()
    const pathname = location?.pathname

    const splitArray = pathname.split('/')

    if (pathname.includes('list') || pathname.includes('add')) {
        const lastTerm = splitArray[splitArray.length - 1]

        index = tabsList.findIndex(ele => {
            return lastTerm === ele
        })
    } else if (pathname.includes('edit')) {
        const secondLastTerm = splitArray[splitArray.length - 2]
        index = tabsList.findIndex(ele => {
            return secondLastTerm === ele
        })
    }

    const [value, setValue] = useState(index > 0 ? index : 0);

    useEffect(() => {
        setValue(index)
    }, [index])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (!splitArray.includes('configuration')) {
            history.push(`/list/${tabsList[newValue]}`)
        } else {
            const path = tabsList[newValue].split('_').join("")
            history.push(`/configuration/${path}`)
        }
    };

    return (
        <Box sx={{ width: '100%', backgroundColor: "white" }}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{ display: "flex", alignItems: "flex-end", height: "64px" }}
            >
                {tabsList.map((label, i) => {
                    return <Tab
                        key={i}
                        value={i}
                        label={label}
                    />
                })}
            </Tabs>
            <Divider />
        </Box>
    );
}

export default TabComp