import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withRouter } from 'react-router-dom';
import { Toolbar, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const drawerWidth = "12%";

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MiniDrawer = (props) => {
    const { drawerList } = props
    const [drawerIndex, setDrawerIndex] = useState()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [tabs, setTabs] = useState([])
    const location = useLocation()
    const pathname = location?.pathname

    useEffect(() => {

        if (pathname === '/' || pathname?.includes('sensordevice')) {
            setDrawerIndex(0)
            setTabs([])
        }
        else if (pathname.includes("list") || pathname.includes("add") || pathname.includes("edit")) setDrawerIndex(1)

    }, [pathname])

    const handleDrawer = (item, i) => {
        setDrawerIndex(i)
        setTabs(item.value)
        props.history.push(`${item.path}`)
    }
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <Drawer variant="permanent" open={drawerOpen}>
            <Toolbar />
            <DrawerHeader>
                <IconButton onClick={handleDrawerToggle}>
                    {drawerOpen === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerHeader>
            <List
                sx={{ pt: 0 }}
            >
                {
                    drawerList.map((item, i) => (
                        <Tooltip placement="right" title={item?.title} key={i}>
                            <ListItem
                                // key={i}
                                disablePadding
                                sx={{
                                    display: 'block',
                                    backgroundColor: drawerIndex === i && "#DC4D28"
                                }}
                                onClick={() => { handleDrawer(item, i) }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 45,
                                        justifyContent: drawerOpen ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: drawerOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: drawerIndex === i && "white"
                                        }}
                                    >
                                        {item?.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.title}
                                        sx={{
                                            opacity: drawerOpen ? 1 : 0,
                                            color: drawerIndex === i && "white"
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
            </List>
        </Drawer>
    )
}

export default withRouter(MiniDrawer)

