import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'

import React from 'react'
import { useDispatch } from 'react-redux';
import { updateFeatureChangeRate, updateFeatureErrors, updateFeatureRange, updateFeatureUnit } from '../../Redux/slices/featureSlice';

const FeatureBox = (props) => {
    const {
        _id,
        name,
        units = [],
        range,
        changeRate,
        formErrors,
        selectedUnit,
        type
    } = props

    const dispatch = useDispatch()

    const handleTextChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'minRange') {
            dispatch(updateFeatureRange({ _id, value: { min: value } }))
            dispatch(updateFeatureErrors([{ _id, minRange: '', maxRange: '' }]))
        }
        if (name === 'maxRange') {
            dispatch(updateFeatureRange({ _id, value: { max: value } }))
            dispatch(updateFeatureErrors([{ _id, minRange: '', maxRange: '' }]))
        }
        if (name === 'changeRate') {
            dispatch(updateFeatureChangeRate({ _id, changeRate: value }))
            formErrors?.changeRate && dispatch(updateFeatureErrors([{ _id, changeRate: '' }]))
        }
    }

    const handleSelectChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'unit') {
            dispatch(updateFeatureUnit({ _id, value }))
        }
    }

    return (
        <Box border="0.5px solid #C4C4C4" borderRadius={'4px'} p={1} m={1}
            sx={{
                width: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: 'flex-start',
                alignItems: "center"
            }}
        >
            <Box fontWeight={'bold'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={0.5} width={'100%'}>
                {name?.toUpperCase()}
                <FormControl fullWidth sx={{ maxWidth: "30%" }} >
                    <InputLabel
                    >Unit</InputLabel>
                    <Select
                        value={selectedUnit || units[0]}
                        label={"Unit"}
                        name={'unit'}
                        onChange={handleSelectChange}
                        size='small'
                    >
                        {units?.map((unit) => {
                            return (
                                <MenuItem value={unit} key={unit}>{unit}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
            {(type === 'range' || type === 'incremental') ? (
                <Box width={'100%'} p={0.5}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                    }}  >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", columnGap: 1 }}>
                            <FormControl sx={{ maxWidth: '40%' }} >
                                <TextField
                                    required
                                    size='small'
                                    sx={{ maxWidth: "100%" }}
                                    error={!!formErrors?.minRange}
                                    label="Minimum value"
                                    type='number'
                                    value={range?.min || ""}
                                    name="minRange"
                                    onChange={handleTextChange}
                                />
                                <FormHelperText error sx={{ mt: -1 }}>{formErrors?.minRange || " "}</FormHelperText>
                            </FormControl>
                            {type === 'range' && <FormControl sx={{ maxWidth: '40%' }} >
                                <TextField
                                    required
                                    size='small'
                                    sx={{ maxWidth: "100%" }}
                                    error={!!formErrors?.maxRange}
                                    label="Maximum value"
                                    type='number'
                                    value={range?.max || ""}
                                    name="maxRange"
                                    onChange={handleTextChange}
                                />
                                <FormHelperText error sx={{ mt: -1 }}>{formErrors?.maxRange || " "}</FormHelperText>
                            </FormControl>}
                            <Typography ml={1} sx={{ overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "20%" }}>({selectedUnit || "unit"})</Typography>
                        </Box>

                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        columnGap: 2
                        // border: "solid"
                    }}>
                        <FormControl sx={{ maxWidth: "40%" }} >

                            <TextField
                                required
                                size='small'
                                sx={{ maxWidth: "100%" }}
                                error={!!formErrors?.changeRate}
                                label="Step value"
                                type='number'
                                value={changeRate || ""}
                                name="changeRate"
                                onChange={handleTextChange}
                            />
                            <FormHelperText error sx={{ mt: -1 }}>{formErrors?.changeRate || " "}</FormHelperText>

                        </FormControl>
                        <Typography sx={{ overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "40%" }}>({selectedUnit || "unit"})</Typography>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Typography>(No Details needed)</Typography>
                </Box>
            )}
        </Box>
    )
}

export default FeatureBox