import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, Stack, IconButton, Switch, Chip } from '@mui/material'
import AddTenantForm from '../Forms/AddTenantForm'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Heading from '../UI/Heading'
import Datatable1 from './DataTable1'
import { CheckCircleOutlined, VisibilityOutlined } from '@mui/icons-material'
import { resetSelectedTenant, setSelectedTenant } from '../../Redux/slices/tenantSlice'
import { GET, PUT, successHandler } from '../../helpers/http'
import swal from 'sweetalert'


const Tenant = (props) => {
    const [openForm, setOpenForm] = useState({ type: '', bool: false })

    const [tenantList, setTenantList] = useState([])

    const { selectedTenant } = useSelector(state => state.tenant)

    const fetchTenants = () => {
        GET(`${process.env.REACT_APP_URL}tenant`)
            .then((response) => {
                if (response?.status === 200) setTenantList(response?.data)
            })
            .catch(error => console.log(error))
    }

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(resetSelectedTenant())
        fetchTenants()
    }, [])



    const fetchTenantById = async (id) => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}tenant/${id}`)
            if (response?.status === 200) {
                return response?.data
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCancel = () => {
        fetchTenants()
        setOpenForm({ type: '', bool: false })
        dispatch(setSelectedTenant({ _id: "", tenantCode: "", dbConnectionUrl: "", isActive: '' }))
    }

    const handleOpenForm = async (type, id) => {
        setOpenForm({ type, bool: true })
        const tenant = tenantList.find(ele => ele._id === id)
        if (tenant) {
            const fetchedTenant = await fetchTenantById(id)
            dispatch(setSelectedTenant({ _id: fetchedTenant?._id, tenantCode: fetchedTenant?.tenantCode, dbConnectionUrl: fetchedTenant?.databaseUrl, isActive: fetchedTenant?.isActive }))
        }
    }

    const handleTenantSelect = async (id) => {

        const tenant = tenantList.find(ele => ele._id === id)
        if (tenant) {
            const fetchedTenant = await fetchTenantById(id)
            dispatch(setSelectedTenant({ _id: fetchedTenant?._id, tenantCode: fetchedTenant?.tenantCode, dbConnectionUrl: fetchedTenant?.databaseUrl, isActive: fetchedTenant?.isActive }))
            history.push('/sensordevices')
        }

    }

    const checkDatabaseUrl = (tenantId) => {
        swal({
            title: "This tenant's DB url will be tested!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (confirm) => {
                try {
                    if (confirm) {
                        const response = await GET(`${process.env.REACT_APP_URL}tenant/validate/db/${tenantId}`)
                        if (response?.status === 200) {
                            successHandler("Tenant's DB connection url validated!")
                        }
                    }
                } catch (error) {
                    console.log(error)
                }

            })
    }

    const updateTenantActiveStatus = async ({ checked, tenantId }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}tenant/${tenantId}`, { isActive: checked })
            if (response?.status === 200) {
                successHandler("Tenant updated successfully")
                fetchTenants()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleActiveChange = ({ checked, tenantId }) => {
        if (!checked) {
            swal({
                title: "All the Deployed sensors will stop generating data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (confirm) => {
                    try {
                        if (confirm) {
                            updateTenantActiveStatus({ checked, tenantId })
                        }
                    } catch (error) {
                        console.log(error)
                    }

                })
        } else {
            updateTenantActiveStatus({ checked, tenantId })
        }
    }

    const columns = [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'tenantCode',
            headerName: 'Tenant Code',
            width: 300,
            valueGetter: (params) => params.row?.tenantCode ? params.row?.tenantCode : 'NA'
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: 300,
            editable: false,
            renderCell: (params) => {
                return <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} width="30%" >
                    <Switch
                        checked={params?.row?.isActive}
                        onChange={(e) => {
                            handleActiveChange({ checked: e.target.checked, tenantId: params?.row?._id })
                        }}
                        name="status"
                        color='warning'
                    />
                    <Chip
                        label={params?.row?.isActive ? 'Active' : 'Inactive'}
                        color={params?.row?.isActive ? 'success' : 'error'}
                    />
                </Box>
            }
        },
        {
            field: 'databaseUrl',
            headerName: 'Database Url',
            width: 500,
            editable: false,
            valueGetter: (params) => params.row?.databaseUrl ? params.row?.databaseUrl : 'NA'
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 500,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        {/* <IconButton
                            size="small"
                            onClick={() => handleOpenForm('edit', cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton> */}
                        <IconButton
                            onClick={() => handleTenantSelect(cellValues.id)}
                        >
                            <VisibilityOutlined fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Sensors
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => checkDatabaseUrl(cellValues.id)}
                        >
                            <CheckCircleOutlined fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Validate DB Url
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            }
        }
    ]

    const rows = tenantList.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    return (
        <Box >
            {/* <Box sx={{ backgroundColor: "", height: "64px", display: "flex", alignItems: "center" }}            >
                <Typography
                    sx={{ ml: 3, color: "black", fontSize: "150%", fontWeight: "bold" }}
                >Tenant Page</Typography>
            </Box>
            <Divider /> */}
            <Heading title={"Tenant Page"} />
            {!openForm.bool && <Box
                sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", p: 2, backgroundColor: "#f1f1f1" }}
            >
                <Button
                    variant="filled"
                    sx={{
                        maxWidth: "220px",
                        width: "150px",
                        backgroundColor: "#DC4D28",
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    onClick={() => { handleOpenForm('add') }}
                >
                    Add Tenant
                </Button>


            </Box>}
            {openForm?.bool && <AddTenantForm
                handleCancel={handleCancel}
                type={openForm.type}
                id={selectedTenant?._id}
            />}
            {!openForm.bool && <Datatable1 columns={columns} rows={rows} pageSize={50} rowsPerPage={50} checkBox={false} />}

        </Box>
    )
}

export default Tenant