import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTenant: {
        _id: '',
        tenantCode: "",
        dbConnectionUrl: "",
        isActive: ''
    },
}

const userSlice = createSlice({
    name: 'tenantSlice',
    initialState,
    reducers: {
        setSelectedTenant: (state, action) => {
            state.selectedTenant = action.payload
        },
        resetSelectedTenant: (state, action) => {
            state.selectedTenant = initialState
        }
    }
})

export const { setSelectedTenant, resetSelectedTenant } = userSlice.actions

export default userSlice.reducer