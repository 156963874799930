import { Box, Button, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateNewDataValue } from '../../Redux/slices/featureSlice'
import { POST, successHandler } from '../../helpers/http'
import { featuresNotAllowedForAnomaly } from '../../helpers/constants'

const AddSingleFeatureData = (props) => {
    const { _id, name, sensorId, selectedUnit, range, incremental, newData, changeRate, type } = props
    const dispatch = useDispatch()

    const { selectedTenant } = useSelector(state => state.tenant)

    const [featureFormErrors, setFeatureFormErrors] = useState({})
    const featureErrors = {}

    const handleTextChange = (e) => {
        const value = e.target?.value
        setFeatureFormErrors({ ...featureFormErrors, newData: "" })
        dispatch(updateNewDataValue({ _id, value }))
    }

    const runValidations = () => {
        if (newData?.length === 0) {
            featureErrors.newData = 'Value cannot be empty'
        }
    }

    const handleInsertData = () => {

        runValidations()

        if (Object.keys(featureErrors)?.length === 0) {
            setFeatureFormErrors({})

            const formObj = {
                "sensorId": sensorId,
                "featureId": _id,
                "value": +newData
            }


            POST(`${process.env.REACT_APP_URL}insert/data`, formObj, "", {
                "tenant-reference-id": selectedTenant?._id
            })
                .then((response) => {
                    if (response?.status === 201) {
                        dispatch(updateNewDataValue({ _id, value: "" }))
                        successHandler(`${name} value inserted successfully`)
                    }
                })
                .catch(error => console.log(error))
        } else {
            setFeatureFormErrors(featureErrors)
        }
    }

    const commonFontSize = {
        fontSize: "17px"
    }
    return (
        <Box
            sx={{
                border: "0.5px solid #C4C4C4",
                borderRadius: "4px",
                p: 2,
                m: 2,
                width: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        // border="solid"
        >
            <Typography
                variant='h6'
                fontWeight={'bold'}
            >{name?.toUpperCase()}</Typography>
            <Box p={2} >
                <Box display={'flex'}>
                    <Typography mr={1} sx={commonFontSize} fontWeight={'bold'}>Unit :</Typography>
                    <Typography sx={commonFontSize}>{selectedUnit || "NA"}</Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography mr={1} sx={commonFontSize} fontWeight={'bold'}>Min value :</Typography>
                    <Typography sx={commonFontSize}>{range?.min || "NA"}</Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography mr={1} sx={commonFontSize} fontWeight={'bold'}>Max value :</Typography>
                    <Typography sx={commonFontSize}>{range?.max || "NA"}</Typography>
                </Box>
                <Box display={'flex'}>
                    <Typography mr={1} sx={commonFontSize} fontWeight={'bold'}>Change rate :</Typography>
                    <Typography sx={commonFontSize}>{changeRate || "NA"}</Typography>
                </Box>
            </Box>
            <Box width={'100%'}
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                }}

            >
                <FormControl
                    sx={{
                        width: "60%"
                    }}
                >
                    <TextField
                        size="small"
                        type="number"
                        label="Value"
                        error={!!featureFormErrors?.newData}
                        value={newData}
                        disabled={featuresNotAllowedForAnomaly?.includes(name)}
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{featureFormErrors?.newData || ""}</FormHelperText>
                </FormControl>
                <Button
                    sx={{
                        height: '40px',
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    disabled={featuresNotAllowedForAnomaly?.includes(name)}
                    onClick={handleInsertData}
                >Insert Data</Button>
            </Box>
        </Box>
    )
}

export default AddSingleFeatureData