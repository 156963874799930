import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button, Switch } from '@mui/material'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { POST, PUT } from '../../helpers/http'

const AddTenantForm = (props) => {
    const { id, handleCancel, type } = props

    const [tenantDetails, setTenantDetails] = useState({
        tenantCode: "",
        dbConnectionUrl: ""
    })
    const [formErrors, setFormErrors] = useState({})

    const history = useHistory()

    const errors = {}
    const selectedTenant = useSelector((state) => state.tenant?.selectedTenant)

    useEffect(() => {
        if (id) {
            setTenantDetails({
                tenantCode: selectedTenant?.tenantCode,
                isActive: selectedTenant?.isActive,
                dbConnectionUrl: selectedTenant?.dbConnectionUrl
            })
        }
    }, [id])

    const handleChange = (e) => {
        const name = e.target.name, value = e.target.value.trim() ? e.target.value : e.target.value.trim()
        if (name === 'tenantCode') setTenantDetails(prevState => {
            return { ...prevState, tenantCode: value }
        })
        if (name === 'dbConnectionUrl') setTenantDetails(prevState => {
            return { ...prevState, dbConnectionUrl: value }
        })
        if (name === 'status') setTenantDetails(prevState => {
            return { ...prevState, isActive: e.target.checked }
        })
    }

    const cancelClick = () => {
        handleCancel()
    }

    const clearForm = () => {
        setTenantDetails({ tenantCode: "", dbConnectionUrl: "" })
    }

    const runValidations = () => {
        if (tenantDetails?.tenantCode?.trim().length === 0) {
            errors.tenantCode = "Tenant code cannot be empty"
        }
        if (tenantDetails?.dbConnectionUrl?.trim().length === 0) {
            errors.dbConnectionUrl = "Tenant DB Connection url cannot be empty"
        }
    }

    const handleSubmit = () => {

        runValidations()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            setFormErrors({})

            const formObj = {}

            if (!id) {
                formObj.tenantCode = tenantDetails?.tenantCode?.trim()
                formObj.databaseUrl = tenantDetails?.dbConnectionUrl?.trim()
            }

            if (id && tenantDetails.hasOwnProperty('isActive')) formObj.isActive = tenantDetails?.isActive

            const redirect = () => {
                history.push('/')
                handleCancel()
            }

            if (id) {
                PUT(`${process.env.REACT_APP_URL}tenant/${id}`, formObj, "Tenant")
                    .then((response) => {
                        if (response?.status === 200) {
                            clearForm()
                            redirect()
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                POST(`${process.env.REACT_APP_URL}tenant`, formObj, "Tenant")
                    .then((response) => {
                        if (response?.status === 201) {
                            clearForm()
                            redirect()
                        }
                    })
                    .catch((error) => {
                        const status = error?.response?.status
                        if (status === 400) {
                            //conflict error & validation error
                            setFormErrors({ tenantCode: "Tenant Code already exists" })
                        }
                    })
            }
        }

    }

    return (
        <Box sx={{ width: "100%", minHeight: "70vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F1F3F4" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "45%", backgroundColor: "white", boxShadow: "2px 2px 4px gray" }} border="solid 1px gray" borderRadius={2}>
                <Typography
                    variant="h6"
                    m={6}
                    fontWeight="bold"
                >{type[0].toUpperCase() + type.slice(1)} Tenant</Typography>
                <TextField
                    value={tenantDetails?.tenantCode}
                    name="tenantCode"
                    onChange={handleChange}
                    label={`Tenant Code *`}
                    sx={{ width: "50%", mb: 5 }}
                    helperText={formErrors.tenantCode}
                    error={!!formErrors.tenantCode}
                    autoFocus
                    disabled={!!id}
                />

                <TextField
                    value={tenantDetails?.dbConnectionUrl}
                    name="dbConnectionUrl"
                    onChange={handleChange}
                    label={`Database Connection Url *`}
                    sx={{ width: "50%", mb: 5 }}
                    helperText={formErrors.dbConnectionUrl}
                    error={!!formErrors.dbConnectionUrl}
                    disabled={!!id}
                />
                {id && <Box border="0.5px solid grey" borderRadius="5px" sx={{ width: "50%", p: 1, mb: 5, display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '60%', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <Typography fontWeight={'bold'}>Active Status</Typography>
                        <Switch
                            checked={tenantDetails?.isActive}
                            onChange={handleChange}
                            name="status"
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='warning'
                        />
                    </Box>
                </Box>}
                <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%", mb: 5 }}>
                    <Button
                        variant="filled"
                        onClick={handleSubmit}
                        sx={{
                            mr: 4,
                            backgroundColor: "#1976D2",
                            color: "white",
                            ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                    >Submit</Button>
                    <Button
                        variant="filled"
                        sx={{
                            backgroundColor: "#333333",
                            color: "white",
                            ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                        onClick={cancelClick}
                    >Cancel</Button>
                </Box>
            </Box>
        </Box >
    )
}

export default withRouter(AddTenantForm)