import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Autocomplete, Chip, Checkbox } from '@mui/material'
import { withRouter } from 'react-router-dom'
import { GET, POST, PUT } from '../../helpers/http'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

const MasterListForm = (props) => {
    const { listType, id, handleCancel } = props
    const [name, setName] = useState('')
    const [formErrors, setFormErrors] = useState({})
    const [featureUnit, setFeatureUnit] = useState('')
    const [allowedUnits, setAllowedUnits] = useState([])

    const defaultListvalues = () => {
        return { selected: "", data: [] }
    }

    const [vendors, setVendors] = useState(defaultListvalues())
    const [features, setFeatures] = useState({ selectedIds: [], data: [] })

    const tabList = ['vendor', 'deviceModel']

    const index = tabList.indexOf(listType)

    const apiCalls = (type, dependencies) => {
        const getData = (api, method) => {
            const masterType = `${api}`
            let url = `${process.env.REACT_APP_URL}${masterType}`

            GET(url)
                .then((response) => {
                    const result = response?.data
                    method((prevState) => {
                        return { ...prevState, data: result }
                    })
                })
                .catch(error => console.log(error))
        }
        if (listType === 'deviceModel') {
            getData('vendor', setVendors)
            getData('feature', setFeatures)
        }
    }

    useEffect(() => {
        apiCalls()
    }, [])

    useEffect(() => {
        if (id) {

            GET(`${process.env.REACT_APP_URL}${listType}/${id}`)
                .then((response) => {
                    const result = response?.data
                    if (response?.status === 200) {
                        setName(result?.name)
                        if (result?.vendorId?._id) setVendors(prevState => {
                            return { ...prevState, selected: result?.vendorId?._id }
                        })
                        if (result?.featureIds?.length > 0) setFeatures(prevState => {
                            return { ...prevState, selectedIds: result?.featureIds?.map(feature => feature?.name) }
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [id, listType])

    const handleChange = (e) => {
        setName(e.target.value)
        setFormErrors({ ...formErrors, name: '' })

    }

    const cancelClick = () => {
        if (listType === 'tenant') {
            handleCancel()
        } else {
            props?.history?.push(`/list/${listType}`)
        }
    }

    const clearForm = () => {
        setName("")
        if (listType === 'feature') {
            setFeatureUnit("")
            setAllowedUnits([])
        }
        setFeatures(prevState => {
            return { ...prevState, selectedIds: [] }
        })
        setVendors(prevState => {
            return { ...prevState, selected: '' }
        })
    }

    const runValidations = () => {
        const errors = {}
        if (name.trim().length === 0) {
            errors.name = "Name cannot be empty"
        }
        if (listType === 'feature') {
            if (featureUnit?.trim()?.length !== 0) errors.allowedUnits = `Please add or remove ${featureUnit} from Units box.(Hit 'enter' to add a unit)`
            else if (allowedUnits?.length === 0) errors.allowedUnits = "Units cannot be empty"

        }
        if (listType === 'deviceModel' && vendors?.selected === "") errors.vendor = 'Please select a vendor'
        if (listType === 'deviceModel' && features?.selectedIds?.length === 0) errors.feature = 'Please select a feature'
        return errors
    }

    const handleSelectChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        if (name === 'vendorIDSelect') {
            setVendors(prevState => {
                return { ...prevState, selected: value }
            })
            setFormErrors({ ...formErrors, vendor: '' })
        }
    }

    const handleUnitsKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const newChipsValue = [...allowedUnits];

            const duplicatedValues = newChipsValue.indexOf(event.target.value.trim());

            if (duplicatedValues !== -1) {
                setFeatureUnit("");
                setFormErrors(prevState => {
                    return { ...prevState, allowedUnits: "" }
                })
                return;
            }

            if (featureUnit.trim() !== "") {
                setAllowedUnits([...allowedUnits, featureUnit.trim()]);
                setFeatureUnit("");
                setFormErrors(prevState => {
                    return { ...prevState, allowedUnits: "" }
                })
            }

        }
    };

    const handleChipDelete = (chipIndex) => {
        const updatedChips = [...allowedUnits];
        updatedChips.splice(chipIndex, 1);
        setAllowedUnits(updatedChips);
    };

    const handleSubmit = () => {
        const errors = runValidations()
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {

            setFormErrors({})

            const formObj = {
                name: name.trim()
            }

            if (listType === 'deviceModel') {
                formObj.vendorId = vendors?.selected
                if (features?.selectedIds?.length > 0) {
                    const selectedIds = []
                    features?.selectedIds?.forEach(feature => {
                        const result = features?.data?.find(ele => ele?.name === feature)
                        if (result) selectedIds.push(result?._id)
                    })
                    formObj.featureIds = selectedIds
                }
            } else if (listType === 'feature') {
                formObj.allowedUnits = allowedUnits
            }

            if (id) {

                PUT(`${process.env.REACT_APP_URL}${listType}/${id}`, formObj, listType)
                    .then((response) => {
                        if (response?.status === 200) {
                            clearForm()
                            props.history.push(`/list/${listType}`)
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === 400) {
                            setFormErrors({ name: `${listType} already existss` })
                        }
                        console.log(error)
                    })

            } else {

                POST(`${process.env.REACT_APP_URL}${listType}`, formObj, listType)
                    .then((response) => {
                        if (response?.status === 201) {
                            clearForm()
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === 400) {
                            setFormErrors({ name: `${listType} already existss` })
                        }
                        console.log(error)
                    })
            }

        } else {
            setFormErrors(errors)
        }
    }

    let selectFields = []
    for (let i = 0; i < index; i++) {
        const field = tabList[i], label = `Select ${field}`, name = `${field}IDSelect`, labelId = `${field}Label`
        let list = []
        if (field === 'vendor') list = vendors

        selectFields.push(
            <FormControl fullWidth sx={{ maxWidth: "450px" }} key={i}>
                <InputLabel id={labelId} error={!!formErrors[field]}>Select {field} *</InputLabel>
                <Select
                    labelId={labelId}
                    error={!!formErrors[field]}
                    value={list?.selected}
                    label={label}
                    name={name}
                    onChange={handleSelectChange}
                >
                    {list?.data?.map((item) => {
                        return (
                            <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText error>{formErrors[field] ? formErrors[field] : null}</FormHelperText>
                <Autocomplete
                    value={features?.selectedIds}
                    onChange={(event, newValue) => {
                        if (newValue.length > 0) {
                            setFeatures(prevState => {
                                return { ...prevState, selectedIds: newValue }
                            })
                            setFormErrors({ ...formErrors, feature: '' })
                        }
                        else if (newValue.length === 0) {
                            setFeatures(prevState => {
                                return { ...prevState, selectedIds: [] }
                            })
                        }
                    }}
                    sx={{ mt: 3 }}
                    noOptionsText="No Data"
                    multiple
                    limitTags={3}
                    disableCloseOnSelect
                    options={features?.data?.map(feature => feature?.name)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    freeSolo
                    renderTags={(selectedEmails, getTagProps) =>
                        selectedEmails.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            error={!!formErrors?.feature}
                            helperText={formErrors?.feature}
                            {...params}
                            label="Select Features"
                            // onChange={handleSearchTermChange}
                            placeholder="Select Features"
                        />
                    )}
                // style={AddGroupModalStyles.autoComplete}
                />
            </FormControl>

        )
    }

    return (
        <Box sx={{ width: "100%", height: listType === 'tenant' || index === -1 ? "70vh" : `${(index + 4) * 14}vh`, display: "flex", justifyContent: "center", backgroundColor: "#F1F3F4" }} mt={20}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "60%", width: "40%", backgroundColor: "white", boxShadow: "2px 2px 4px gray" }} border="solid 1px #f1f1f1" borderRadius={2}>
                <Typography
                    variant="h5" fontWeight="bold"
                >{id ? "Edit this" : "Add a New"} {listType.charAt(0).toUpperCase() + listType.slice(1)}</Typography>

                <TextField
                    value={name}
                    onChange={handleChange}
                    label={`Enter ${listType} Name`}
                    fullWidth sx={{ maxWidth: "450px" }}
                    helperText={formErrors.name}
                    error={!!formErrors.name}
                    autoFocus
                />
                {listType === 'feature' && <TextField
                    multiline
                    fullWidth sx={{ maxWidth: "450px" }}
                    label='Feature Units'
                    value={featureUnit}
                    onChange={(event) => setFeatureUnit(event.target.value)}
                    onKeyDown={handleUnitsKeyPress}
                    placeholder='Hit enter to add a unit'
                    InputProps={{
                        style: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            rowGap: 1,
                        },
                        startAdornment: allowedUnits?.map((chip, index) => (
                            <Chip
                                key={index}
                                label={chip}
                                onDelete={() => handleChipDelete(index)}
                                style={{ margin: 2 }}
                            />
                        ))
                    }}
                    helperText={formErrors.allowedUnits}
                    error={!!formErrors.allowedUnits}
                />}

                {selectFields}
                <Box sx={{ display: 'flex', justifyContent: "space-evenly", width: "30%" }}>
                    <Button
                        variant="filled"
                        onClick={handleSubmit}
                        sx={{
                            mr: 4,
                            backgroundColor: "#1976D2",
                            color: "white",
                            ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                    >{listType === 'tenant' ? "Next" : "Confirm"}</Button>
                    <Button
                        variant="filled"
                        sx={{
                            backgroundColor: "#333333",
                            color: "white",
                            ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                            width: "200px"
                        }}
                        onClick={cancelClick}
                    >Cancel</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(MasterListForm)