import React, { useEffect, useState } from 'react'
import Heading from '../UI/Heading'
import DataTable1 from '../Main/DataTable1'
import { Stack, Typography, IconButton, Button, Box } from '@mui/material'
import { DeleteOutline, EditOutlined, Circle, RocketLaunchOutlined, DoNotDisturbOnOutlined, AddCircleOutline } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import swal from 'sweetalert'
import { DELETE, GET, POST, successHandler } from '../../helpers/http'
import AddDataModal from '../UI/AddDataModal'
import { setSelectedFeatures } from '../../Redux/slices/featureSlice'

const SensorDevicePage = (props) => {
    const history = useHistory()
    const { selectedTenant } = useSelector(state => state?.tenant)
    const dispatch = useDispatch()

    const [sensorList, setSensorList] = useState([])
    const [sensorsDeployed, setSensorsDeployed] = useState({})
    const [sensorsNotDeployed, setSensorsNotDeployed] = useState({})

    const [selectedSensor, setSelectedSensor] = useState({})
    const [dataModalOpen, setDataModalOpen] = useState(false)

    const fetchSensors = () => {
        GET(`${process.env.REACT_APP_URL}sensor`, {
            "tenant-reference-id": selectedTenant?._id
        })
            .then((response) => {
                if (response?.status === 200) {
                    setSensorList(response?.data)
                }
            })
            .catch(error => console.log(error))
    }

    const fetchActiveSensors = async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}sensor/deploy/count`, {
                "tenant-reference-id": selectedTenant?._id
            })
            if (response?.status === 200) {
                const {
                    sensorsDeployed,
                    sensorsNotDeployed
                } = response?.data
                setSensorsDeployed(sensorsDeployed)
                setSensorsNotDeployed(sensorsNotDeployed)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteItem = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    DELETE(`${process.env.REACT_APP_URL}sensor/${id}`)
                        .then((response) => {
                            if (response?.status === 200) {
                                successHandler('sensor deleted successfully')
                                fetchSensors()
                                fetchActiveSensors()
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
    }

    const handleEditItem = (id) => {
        props?.history?.push(`/sensordevice/edit/${id}`)
    }



    useEffect(() => {
        if (selectedTenant?._id) {
            fetchSensors()
            fetchActiveSensors()
        }
    }, [])

    const handleAddDataClick = (sensorData) => {
        const { features, _id, sensorId } = sensorData
        setSelectedSensor({ _id, sensorId })
        if (features?.length > 0) {
            const featuresArr = features?.map(feature => {
                const featureObj = {}
                featureObj._id = feature?.featureId?._id
                featureObj.name = feature?.featureId?.name
                featureObj.units = feature?.featureId?.allowedUnits
                if (feature?.type === 'range') {
                    featureObj.range = { min: feature?.requirements?.range?.min, max: feature?.requirements?.range?.max }
                }
                else if (feature?.type === 'incremental') {
                    featureObj.range = { min: feature?.requirements?.isIncremental?.min }
                }
                featureObj.changeRate = feature?.requirements?.changeRate
                featureObj.selectedUnit = feature?.requirements?.unit
                featureObj.newData = ""
                return featureObj
            })

            dispatch(setSelectedFeatures(featuresArr))
            setDataModalOpen(true)
        }
    }

    const columns = [
        {
            field: 'isActive',
            headerName: 'Active',
            width: 80,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Circle sx={{ color: cellValues?.value ? 'green' : 'red', height: "20px" }} />
                )
            }
        },
        {
            field: 'isDeployed',
            headerName: 'Deployed',
            width: 80,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Circle sx={{ color: cellValues?.value ? 'green' : 'red', height: "20px" }} />
                )
            }
        },
        {
            field: 'isAllRequirementsMet',
            headerName: 'Are Requirements met',
            width: 120,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Circle sx={{ color: cellValues?.value ? 'green' : 'red', height: "20px" }} />
                )
            }
        },
        {
            field: 'sensorId',
            headerName: 'Sensor ID',
            width: 100,
            editable: false,
        },
        {
            field: 'locationReference',
            headerName: 'Location',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.location ? params.row?.locationReferences?.location : 'NA'
        },
        {
            field: 'buildingReference',
            headerName: 'Building',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.building ? params.row?.locationReferences?.building : 'NA'
        },
        {
            field: 'floorReference',
            headerName: 'Floor',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.floor ? params.row?.locationReferences?.floor : 'NA'
        },
        {
            field: 'zoneReference',
            headerName: 'Zone',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.zone ? params.row?.locationReferences?.zone : 'NA'
        },
        {
            field: 'subzoneReference',
            headerName: 'Sub Zone',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.subzone ? params.row?.locationReferences?.subzone : 'NA'
        },
        {
            field: 'areaReference',
            headerName: 'Area',
            width: 110,
            editable: false,
            valueGetter: (params) => params.row?.locationReferences?.area ? params.row?.locationReferences?.area : 'NA'
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            width: 150,
            valueGetter: (params) => params.row?.vendorReferenceId?.name ? params.row?.vendorReferenceId?.name : 'NA'
        },
        {
            field: 'deviceModel',
            headerName: 'Device Model',
            width: 150,
            editable: false,
            valueGetter: (params) => params.row?.deviceModelReferenceId?.name ? params.row?.deviceModelReferenceId?.name : 'NA'
        },
        {
            field: 'feature',
            headerName: 'Features',
            width: 300,
            editable: false,
            valueGetter: (params) => params.row?.features?.length > 0 ? params.row?.features?.map(ele => ele?.featureId?.name).join(', ') : 'NA'
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 300,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleAddDataClick(cellValues?.row)}
                        >
                            <AddCircleOutline fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Add Anomaly
                            </Typography>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton>
                    </Stack>
                )
            }
        }

    ]

    const rows = sensorList.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    const actionOnSensors = async ({ deploy, formObj }) => {
        try {
            const response = await POST(`${process.env.REACT_APP_URL}sensor/deploy`, formObj, '', {
                "tenant-reference-id": selectedTenant?._id
            })
            if (response?.status === 200) {
                fetchActiveSensors()
                fetchSensors()
                successHandler(`Actions on sensors successful`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSensorsAction = async ({ deploy }) => {
        let formObj = {}
        if (deploy === 'stop') {
            swal({
                title: "The sensors will stop generating data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((confirm) => {
                    if (confirm) {
                        formObj.deployType = deploy
                        formObj.sensorIds = sensorsDeployed?.data
                        actionOnSensors({ formObj })
                    }
                })
        } else {
            formObj.deployType = deploy
            formObj.sensorIds = sensorsNotDeployed?.data
            actionOnSensors({ formObj })
        }
    }

    const handleDataModalClose = () => {
        setDataModalOpen(false)
        dispatch(setSelectedFeatures([]))
        setSelectedSensor({})
    }

    return (
        <Box>
            <Heading title={"Sensor Devices"} />
            <Box display="flex" justifyContent="flex-end" height={"60px"} alignItems={"center"} bgcolor={"#f1f1f1"}>
                <Button
                    variant="filled"
                    startIcon={<RocketLaunchOutlined />}
                    sx={{
                        maxWidth: "300px",
                        mr: 3,
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    disabled={sensorsNotDeployed?.count === 0}
                    onClick={() => { handleSensorsAction({ deploy: "start" }) }}
                >
                    {sensorsNotDeployed?.count > 0 ? `Deploy ${sensorsNotDeployed?.count} sensor(s)` : `Deploy sensors`}
                </Button>
                <Button
                    variant="filled"
                    startIcon={<DoNotDisturbOnOutlined />}
                    sx={{
                        maxWidth: "300px",
                        mr: 3,
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    disabled={sensorsDeployed?.count === 0}
                    onClick={() => { handleSensorsAction({ deploy: "stop" }) }}
                >
                    {sensorsDeployed?.count > 0 ? `Stop ${sensorsDeployed?.count} sensors` : `Stop sensors`}
                </Button>
                <Button
                    variant="filled"
                    sx={{
                        maxWidth: "220px",
                        width: "200px",
                        mr: 3,
                        backgroundColor: '#DC4D28',
                        color: 'white',
                        ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                    }}
                    onClick={() => { history?.push(`/sensordevice/add`) }}
                >
                    Add Sensor Device
                </Button>
            </Box>
            <DataTable1 rows={rows} columns={columns} pageSize={50} rowsPerPage={50} checkBox={false} />
            {dataModalOpen && <AddDataModal open={dataModalOpen} onClose={handleDataModalClose} selectedSensor={selectedSensor} />}
        </Box >
    )
}

export default SensorDevicePage