import React, { useState, useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { withRouter } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';

import { styled, Box, Toolbar, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert';
import { logoutUser } from '../../Redux/slices/userSlice';
import { setSelectedTenant } from '../../Redux/slices/tenantSlice';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Header = (props) => {
    const [time, setTime] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const selectedTenant = useSelector(state => state?.tenant?.selectedTenant?._id)
    const tenantName = useSelector(state => state.tenant?.selectedTenant?.tenantCode)
    useEffect(() => {
        const currentTime = setInterval(() => {
            const today = new Date().toLocaleString('en-US').split(',')[1].trim()
            setTime(today)
        }, 1000)
        return () => { clearInterval(currentTime) }
    }, [])

    const handleLogoutClick = () => {
        swal({
            title: "Sure you want to Logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirm) => {
                if (confirm) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('tenantId')
                    dispatch(logoutUser())
                    props.history.push('/login')
                }
            })
    }


    const handleExitTenant = () => {
        localStorage.removeItem('tenantId')
        dispatch(setSelectedTenant({
            _id: '',
            tenantCode: "",
            dbConnectionUrl: "",
            isActive: ''
        }))
        history.push('/')
    }

    return (
        <Box sx={{ position: "static", display: "flex", flexGrow: 1, width: "100%", height: "64px" }}>
            <AppBar color="" >
                <Toolbar>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "66px", height: "65px", ml: "-25px" }} color="white" backgroundColor="#DC4D28"><AppsIcon sx={{ fontSize: "35px", }} /></Box>
                    <Typography sx={{ ml: 3, flexGrow: 1, fontWeight: "bolder", color: "#DC4D28", fontSize: "160%" }}>
                        Simulation Engine {tenantName && ` -   ${tenantName}`}
                    </Typography>
                    <Box>
                        <Typography mr={3} fontWeight={"bold"}>{time}</Typography>
                    </Box>
                    {selectedTenant && location.pathname !== "/" && <Button
                        color="inherit"
                        variant="outlined"
                        onClick={handleExitTenant}
                        sx={{
                            backgroundColor: "#757575",
                            color: "white",
                            width: "130px",
                            borderRadius: "8px",
                            ':hover': { bgcolor: '#757575', color: 'white', fontWeight: "bold" },
                            mr: 2
                        }}
                    >Exit Tenant</Button>}
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={handleLogoutClick}
                        sx={{
                            backgroundColor: "#b71c1c",
                            borderRadius: "8px",
                            color: "white",
                            width: "100px",
                            ':hover': { bgcolor: "#b71c1c", color: 'white', fontWeight: "bold" }
                        }}
                    >Logout</Button>
                </Toolbar>
            </AppBar>
        </Box >
    );
}

export default withRouter(Header)