import { Box, Modal, Paper, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import AddSingleFeatureData from './AddSingleFeatureData'
import { CloseOutlined } from '@mui/icons-material'

const AddDataModal = (props) => {
    const { open, onClose, selectedSensor: { sensorId } } = props

    const features = useSelector(state => state?.features?.selected)
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Paper
                sx={{
                    maxHeight: "80vh",
                    width: "45vw",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 3,
                    borderRadius: 2,
                    overflow: 'auto',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '0.4em',
                        borderRadius: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        borderRadius: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                    },
                }}
            >
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} p={2}>
                    <Typography variant='h5' fontWeight={'bold'}> Add Anomaly : {sensorId}</Typography>
                    <CloseOutlined
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        onClick={onClose}
                    />
                </Box>
                <Box width={'100%'} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} p={2}>
                    {features?.length > 0 && features?.map(feature => {
                        return <AddSingleFeatureData
                            sensorId={sensorId}
                            key={feature?._id}
                            {...feature}
                        />
                    })}
                </Box>
            </Paper>
        </Modal>
    )
}

export default AddDataModal