import InputForm from "./InputForm";
import BasicCard from "./BasicCard";

import React from 'react'
import { Box } from "@mui/material";

export const LoginForm = (props) => {
    const getContent = () => <InputForm type="login" {...props} />;
    return (<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <BasicCard content={getContent()} />;
    </Box>)
}