
import { Route } from 'react-router-dom';

import { LoginForm } from '../Forms/Form';


export const PublicRoutes = (routesProps) => {

    return (
        <>
            <Route path="/login" render={(props) => {
                return <LoginForm {...props} {...routesProps} />
            }} exact />
        </>
    )
}
