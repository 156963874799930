import React, { useState } from "react";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, Box, styled } from "@mui/material";

const Datatable1 = (props) => {
    const ODD_OPACITY = 0.2
    const [pageNum, setPageNum] = useState(0)
    const color = "#282a2e"

    const bgcolor = "#fff"

    const scrollStyle = {
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '8px',

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            // outline: '1px solid lightgrey'
        }
    }
    // background: "#eceff3"
    // dark: "#282a2e"
    // dim: "#808080"
    // light: "#fff"
    // main: "#ef5350"

    const StripedDataGrid = styled(DataGrid)(({ theme }) => (
        {
            [`& .${gridClasses.row}.even`]: {
                backgroundColor: theme.palette.grey[100],
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha("#282a2e", ODD_OPACITY),
                    color,
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                        color,
                    },
                },
                '&.Mui-selected': {
                    backgroundColor: alpha(
                        "#282a2e",
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                    color,
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha(
                            "#282a2e",
                            ODD_OPACITY +
                            theme.palette.action.selectedOpacity +
                            theme.palette.action.hoverOpacity,
                        ),
                        color,
                    },
                },
                ...scrollStyle
            },
            [`& .${gridClasses.row}.odd`]: {
                backgroundColor: "white",
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha("#282a2e", ODD_OPACITY),
                    color,
                    '@media (hover: none)': {
                        backgroundColor: 'transparent',
                        color,
                    },
                },
                '&.Mui-selected': {
                    backgroundColor: alpha(
                        "#282a2e",
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                    color,
                    '&:hover, &.Mui-hovered': {
                        backgroundColor: alpha(
                            "#282a2e",
                            ODD_OPACITY +
                            theme.palette.action.selectedOpacity +
                            theme.palette.action.hoverOpacity,
                        ),
                        color,
                    },
                },
                ...scrollStyle
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: bgcolor,
                color: "black",
            },
        }
    ))

    return (
        <Box sx={{ height: 820, width: '100%', backgroundColor: "white" }}>
            <StripedDataGrid
                sx={scrollStyle}
                rows={props.rows}
                columns={props.columns}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                page={pageNum}
                pageSize={props.pageSize || 10}
                style={{ fontSize: '14px' }}
                rowsPerPageOptions={[props.rowsPerPage || 100]}
                checkboxSelection={props.checkBox !== false || false}
                disableSelectionOnClick
                // onSelectionModelChange={(newSelectionModel) => {
                //     props.setSelectionModel(newSelectionModel);
                // }}
                getRowId={(row) => row.id ? row.id : row._id}
                onPageChange={(newPage) => setPageNum(newPage)}
                // selectionModel={props.selectionModel}
                // showColumnRightBorder
                // showCellRightBorder
                experimentalFeatures={{ newEditingApi: true }}

            // onCellClick={(event) => props.onAssetClick ? props.onAssetClick(event) : null}
            />
        </Box>
    )
}


export default Datatable1;