import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import App from './App';
import storeConfigure from './Redux/store/store'

const store = storeConfigure()

console.log("Initial Store Value", store.getState())

store.subscribe(() => {
  console.log("Store Updated", store.getState())
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
