import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "../slices/userSlice";
import tenantSlice from "../slices/tenantSlice";
import featureSlice from "../slices/featureSlice";

const storeConfigure = () => {
    const rootReducer = combineReducers({

        user: userSlice,
        tenant: tenantSlice,
        features: featureSlice

    })
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });
    return store
}

export default storeConfigure;