import React, { useEffect, useState } from 'react'
import TabComp from "../Main/Tabs"
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import Datatable1 from '../Main/DataTable1'
import { Stack, Typography, IconButton, Button, Box } from '@mui/material'
import { EditOutlined } from '@mui/icons-material'
import swal from 'sweetalert'
import MasterListForm from '../Forms/MasterListForm'
import { DELETE, GET, successHandler } from '../../helpers/http'

const MasterList = (props) => {

    const tabs = ['vendor', 'feature', 'deviceModel']
    const history = useHistory()
    const location = useLocation()
    const match = useRouteMatch()
    const { listType, id } = match.params

    const openAddForm = location.pathname.split('/').includes('add')
    const openEditForm = location.pathname.split('/').includes('edit')
    // const { data } = useSelector(state => state?.masterList[listType])

    const [data, setData] = useState([
        {
            "_id": "658bf03a8c9bfcd4db2f0890",
            "name": "Disruptive1",
            "isDeleted": false,
            "createdAt": "2023-12-27T09:36:58.095Z",
            "updatedAt": "2023-12-27T09:36:58.095Z",
            "__v": 0
        }

    ])

    const apiCalls = () => {
        GET(`${process.env.REACT_APP_URL}${listType}`)
            .then((response) => {
                if (response?.status === 200) {
                    setData(response?.data)
                }
            })
            .catch(error => console.log(error))

    }

    useEffect(() => {
        if (listType && !openAddForm && !openEditForm) {
            apiCalls()
        }
        setData([])
    }, [listType])


    const handleDeleteItem = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    DELETE(`${process.env.REACT_APP_URL}${listType}/${id}`)
                        .then((response) => {
                            if (response?.status === 200) {
                                apiCalls()
                                successHandler(`${listType} deleted successfully`)
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
    }

    const handleEditItem = (id) => {
        if (listType) {
            props?.history?.push(`/edit/${listType}/${id}`)
        }
    }

    const columns = listType === 'deviceModel' ? [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300,
            valueGetter: (params) => {
                return params?.row?.name
            }
        },
        {
            field: 'vendorId',
            headerName: 'Vendor',
            width: 300,
            valueGetter: (params) => {
                return params?.value?.name
            }
        },
        {
            field: 'featureIds',
            headerName: 'Features',
            width: 400,
            valueGetter: (params) => {
                return params?.value?.map(feature => feature?.name)?.join(', ')
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 250,
            valueGetter: (params) => `${params?.row?.createdAt ? moment(params?.row?.createdAt).format('lll') : "NA"}`
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 250,
            valueGetter: (params) => `${params?.row?.updatedAt ? moment(params?.row?.updatedAt).format('lll') : "NA"}`
        },
        {
            field: 'Actions',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        {/* <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton> */}
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ] : [
        {
            field: 'SrNo',
            headerName: 'Sr. No.',
            width: 100,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 250
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            valueGetter: (params) => {
                if (listType === 'feature') {
                    const unitString = params.row?.allowedUnits?.length > 0 ? `(${params.row?.allowedUnits?.join(', ')})` : ""
                    return `${params.row?.name} ${unitString}`
                }
                else return params.row.name
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 250,
            valueGetter: (params) => `${params?.row?.createdAt ? moment(params?.row?.createdAt).format('lll') : "NA"}`
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 250,
            valueGetter: (params) => `${params?.row?.updatedAt ? moment(params?.row?.updatedAt).format('lll') : "NA"}`
        },
        {
            field: 'Actions',
            renderCell: (cellValues) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                            size="small"
                            onClick={() => handleEditItem(cellValues.id)}
                        >
                            <EditOutlined fontSize="small" sx={{ color: '#1976D2' }} />
                            <Typography color={'#1976D2'} variant={'caption'}>
                                &nbsp;Edit
                            </Typography>
                        </IconButton>
                        {/* <IconButton
                            onClick={() => handleDeleteItem(cellValues.id)}
                        >
                            <DeleteOutline fontSize="small" sx={{ color: '#DC4D28' }} />
                            <Typography color={'#DC4D28'} variant={'caption'}>
                                &nbsp;Delete
                            </Typography>
                        </IconButton> */}
                    </Stack>
                )
            },
            width: 250,
            sortable: false,
        },
    ]

    const rows = data?.map((row, i) => {
        return { ...row, id: row._id, SrNo: i + 1 }
    })

    return (
        <Box>
            <TabComp tabsList={tabs} />
            <Box sx={{ backgroundColor: "#F1F1F1", border: "1px solid #f1f1f1", height: "85vh" }}>
                {(!openAddForm && !openEditForm) ? <>
                    <Box display="flex" justifyContent="flex-end" height={"60px"} alignItems={"center"} bgcolor={"#f1f1f1"}>
                        <Button
                            variant="filled"
                            sx={{
                                maxWidth: "220px",
                                width: "200px",
                                // alignSelf: 'end',
                                mr: 3,
                                // mb: 2,
                                // mt: 1,
                                backgroundColor: '#DC4D28',
                                color: 'white',
                                ':hover': { bgcolor: '#DC4D28', color: 'white', fontWeight: "bold" }
                            }}
                            onClick={() => { history?.push(`/add/${listType}`) }}
                        >
                            Add {listType}
                        </Button>
                    </Box>
                    <Datatable1 rows={rows} columns={columns} pageSize={20} rowsPerPage={20} checkBox={false} />
                </>
                    : <MasterListForm
                        listType={listType}
                        id={id}
                    />
                }
            </Box>
        </Box>
    )
}

export default MasterList