import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogged: !!localStorage.getItem('token'),
}

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.isLogged = true
        },
        logoutUser: (state, action) => {
            state.isLogged = false
        }

    }
})

export const { loginUser, logoutUser } = userSlice.actions

export default userSlice.reducer