import Main from './components/Main'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline, createTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import HomePage from './components/HomePage'
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'


const App = () => {

  const history = useHistory()
  const { isLogged } = useSelector(state => state.user)
  const location = useLocation()
  const pathname = location?.pathname?.split('/')

  useEffect(() => {
    if (isLogged) {
      history.push('/')
    }
    else if (!isLogged && (pathname[1] === 'login' || pathname[1] === '')) history.push('/login')
    else history.push('/login')
  }, [isLogged])

  const theme = createTheme()

  return (
    <Box width={"100%"}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!isLogged ? <Main /> : <HomePage />}
      </ThemeProvider >
    </Box>
  )
}

export default App