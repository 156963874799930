import React, { useEffect } from 'react'
import Header from './UI/Header'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import MiniDrawer from './UI/Drawer'
import { withRouter } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ApartmentOutlined, ListAlt, Sensors } from '@mui/icons-material';
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import Tenant from './Main/Tenant'
import MasterList from './Pages/MasterList'
import SensorDevicePage from './Pages/SensorDevicesPage'
import SensorDevicesForm from './Forms/SensorDevicesForm'


const HomePage = (props) => {
    const history = useHistory()

    const { selectedTenant } = useSelector(state => state.tenant)

    const tenantId = selectedTenant?._id

    useEffect(() => {
        history.push('/')
    }, [])

    const drawerList = tenantId ? ([
        { index: 0, title: 'Sensor Devices', icon: <Sensors />, path: '/sensordevices', value: [] },
    ]) : [
        { index: 0, title: 'Tenant List', icon: <ApartmentOutlined />, path: '/', value: [] },
        { index: 1, title: 'Master List', icon: <ListAlt />, path: '/list/vendor', value: ['vendor', 'feature', 'deviceModel'] },
    ]

    return (
        <Box>
            <Header />
            <Box display={"flex"} flexGrow={1} sx={{ height: "92vh" }} >
                <MiniDrawer drawerList={drawerList}
                />
                <Box width={"100vw"}>
                    <Route path="/" component={Tenant} exact />
                    <Route path="/list/:listType" component={MasterList} exact />
                    <Route path="/add/:listType" component={MasterList} exact />
                    <Route path="/edit/:listType/:id" component={MasterList} exact />
                    <Route path="/sensordevices" component={SensorDevicePage} exact />
                    <Route path="/sensordevice/add" component={SensorDevicesForm} exact />
                    <Route path="/sensordevice/edit/:id" component={SensorDevicesForm} exact />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                        theme="colored"
                    />
                </Box>
            </Box>
        </Box >
    )
}

export default withRouter(HomePage)
