import axios from "axios"
import { toast } from 'react-toastify'


export const errorHandler = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    })
}

export const successHandler = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    })
}

const setHeaders = () => {
    const authObj = {}
    if (localStorage.getItem('token')) authObj.Authorization = localStorage.getItem('token')
    return authObj
}


export const GET = async (endpoint, headers) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get(endpoint, { headers: { ...setHeaders(), ...headers } })
            resolve(response);
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
            errorHandler(error?.response?.data?.message || error?.response?.data?.error)
            reject(error);
        }
    });
}

export const POST = async (endpoint, body, label, headers) => {

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(endpoint, body, { headers: { ...setHeaders(), ...headers } })
            if (label) successHandler(`${label.slice(0, 1).toUpperCase()}${label.slice(1)} added sucessfully`)
            resolve(response)
        } catch (error) {
            console.log(error?.response)
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                if (window?.location?.pathname !== '/login') window?.location?.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
            else if (error?.response?.status === 409) errorHandler(error?.response?.data[0]?.message)
            else errorHandler(error.response?.data?.message || error?.response?.data?.error)
            console.log({ error, label })
            reject(error);
        }
    })
}

export const PUT = async (endpoint, body, label) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(endpoint, body, { headers: setHeaders() })
            if (label) successHandler(`${label.slice(0, 1).toUpperCase()}${label.slice(1)} updated`)
            resolve(response)
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
            errorHandler(error?.response?.data?.message || error?.response?.data?.error)
            reject(error);
        }
    })
}

export const DELETE = async (endpoint) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(endpoint, { headers: setHeaders() })
            resolve(response);
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
            errorHandler(error.message)
            reject(error);
        }
    });
}