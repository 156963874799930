import React from 'react'
import { Box } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { PublicRoutes } from './Main/Routes';

const Main = (props) => {
    return (
        <>
            <Box >
                {<video
                    autoPlay
                    loop
                    muted
                    style={{
                        position: 'fixed',
                        zIndex: -1,
                        width: '100%'
                    }}
                >
                    <source src={process.env.REACT_APP_BACKGROUND_VIDEO_URL} type='video/webm' />
                    Your browser does not support the video tag.
                </video>}
                <PublicRoutes {...props} />
            </Box>
        </>
    )
}

export default withRouter(Main)