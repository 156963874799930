import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selected: [],
}

const featureSlice = createSlice({
    name: 'featureSlice',
    initialState,
    reducers: {
        setSelectedFeatures: (state, action) => {
            state.selected = action.payload
        },
        updateFeatureUnit: (state, action) => {
            state.selected = state?.selected?.map(feature => {
                if (feature?._id === action.payload?._id) {
                    return { ...feature, selectedUnit: action.payload?.value }
                } else {
                    return { ...feature }
                }
            })
        },
        updateFeatureRange: (state, action) => {
            state.selected = state?.selected?.map(feature => {
                if (feature?._id === action.payload?._id) {
                    return { ...feature, range: { ...feature?.range, ...action.payload?.value } }
                } else {
                    return { ...feature }
                }
            })
        },
        updateFeatureChangeRate: (state, action) => {
            state.selected = state?.selected?.map(feature => {
                if (feature?._id === action.payload?._id) {
                    return { ...feature, changeRate: action.payload?.changeRate }
                } else {
                    return { ...feature }
                }
            })
        },
        updateNewDataValue: (state, action) => {
            state.selected = state?.selected?.map(feature => {
                if (feature?._id === action.payload?._id) {
                    return { ...feature, newData: action.payload?.value }
                } else {
                    return { ...feature }
                }
            })
        },
        updateFeatureErrors: (state, action) => {
            const errorsArr = action.payload
            const newFeatures = state?.selected?.map(feature => {
                const errorForThatFeature = errorsArr.find(ele => ele?._id === feature?._id)
                if (errorForThatFeature) {
                    delete errorForThatFeature._id
                    return { ...feature, formErrors: errorForThatFeature }
                } else {
                    return { ...feature }
                }
            })
            return { selected: newFeatures }
        },
    }
})

export const {
    setSelectedFeatures,
    updateFeatureRange,
    updateFeatureChangeRate,
    updateFeatureErrors,
    updateFeatureUnit,
    updateNewDataValue
} = featureSlice.actions

export default featureSlice.reducer