import { Box, Button, Checkbox, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Slider, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GET, POST, PUT, successHandler } from '../../helpers/http'
import { useHistory } from 'react-router-dom'
import { setSelectedFeatures, updateFeatureErrors } from '../../Redux/slices/featureSlice'
import { useDispatch, useSelector } from 'react-redux'
import FeatureBox from './FeatureBox'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css';
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'
import swal from 'sweetalert'

const SensorDevicesForm = () => {
    const match = useRouteMatch()

    const { id } = match?.params

    const initialState = { selected: '', data: [] }
    const [sensorActive, setSensorActive] = useState(false)
    const [sensorQuantity, setSensorQuantity] = useState('1')
    const [sensorDeployed, setSensorDeployed] = useState(false)
    const [sensorId, setSensorId] = useState('')
    const [sensorName, setSensorName] = useState('')
    const [location, setLocation] = useState('')
    const [building, setBuilding] = useState(``)
    const [floor, setFloor] = useState('')
    const [zone, setZone] = useState('')
    const [subzone, setSubzone] = useState('')
    const [area, setArea] = useState('')
    const [vendor, setVendor] = useState(initialState)
    const [totalDeviceModel, setTotalDeviceModel] = useState({ data: [] })
    const [deviceModel, setDeviceModel] = useState(initialState)

    const [isDataContinuos, setIsDataContinuous] = useState(false)
    const [fromTimeRange, setFromTimeRange] = useState('')

    const [toTimeRange, setToTimeRange] = useState('')
    // const [dataFrequency, setDataFrequency] = useState(600)

    const [formErrors, setFormErrors] = useState({})

    const dispatch = useDispatch()

    const errors = {}
    const featureErrors = []

    const history = useHistory()

    const features = useSelector(state => state?.features)
    const { selectedTenant } = useSelector(state => state?.tenant)

    const fetchVendor = () => {
        GET(`${process.env.REACT_APP_URL}vendor`)
            .then((response) => {
                if (response?.status === 200) {
                    setVendor(prevState => {
                        return { ...prevState, data: response?.data }
                    })
                }
            })
            .catch(error => console.log(error))
    }

    const fetchDeviceModel = () => {
        GET(`${process.env.REACT_APP_URL}deviceModel`)
            .then((response) => {
                if (response?.status === 200) {
                    setTotalDeviceModel(prevState => {
                        return { ...prevState, data: response?.data }
                    })
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        fetchVendor()
        fetchDeviceModel()

        return () => {
            setVendor(initialState)
            setDeviceModel(initialState)
            setTotalDeviceModel([])
            dispatch(setSelectedFeatures([]))
        }
    }, [])

    useEffect(() => {
        if (vendor?.selected) {
            if (id) {
                setDeviceModel(prevState => {
                    return { ...prevState, data: totalDeviceModel?.data?.filter(deviceModel => deviceModel?.vendorId?._id === vendor?.selected) }
                })
            } else {
                setDeviceModel({ data: totalDeviceModel?.data?.filter(deviceModel => deviceModel?.vendorId?._id === vendor?.selected), selected: '' })
            }

        }
    }, [vendor?.selected])

    useEffect(() => {
        if (id) {
            GET(`${process.env.REACT_APP_URL}sensor/${id}`)
                .then((response) => {
                    if (response?.status === 200) {
                        const {
                            timeRange: { isContinuous, startTime, endTime },
                            // dataFrequency,
                            isActive,
                            isDeployed,
                            sensorId,
                            sensorName,
                            locationReferences: {
                                location,
                                building,
                                floor,
                                zone,
                                subzone,
                                area
                            } = {},
                            vendorReferenceId,
                            deviceModelReferenceId,
                            features,
                        } = response?.data
                        setIsDataContinuous(isContinuous)
                        if (!isContinuous) {
                            setFromTimeRange(startTime)
                            setToTimeRange(endTime)
                        }
                        setSensorActive(isActive)
                        setSensorDeployed(isDeployed)
                        // if (dataFrequency) (setDataFrequency(dataFrequency))
                        if (sensorId) (setSensorId(sensorId))
                        if (sensorName) (setSensorName(sensorName))
                        if (location) (setLocation(location))
                        if (building) (setBuilding(building))
                        if (floor) (setFloor(floor))
                        if (zone) (setZone(zone))
                        if (subzone) (setSubzone(subzone))
                        if (area) (setArea(area))
                        if (vendorReferenceId?._id) {
                            setVendor(prevState => {
                                return { ...prevState, selected: vendorReferenceId?._id }
                            })
                        }
                        if (deviceModelReferenceId?._id) {
                            setDeviceModel(prevState => {
                                return { ...prevState, selected: deviceModelReferenceId?._id }
                            })
                        }
                        if (features?.length > 0) {
                            const featuresArr = features?.map(feature => {
                                const featureObj = {}
                                featureObj._id = feature?.featureId?._id
                                featureObj.name = feature?.featureId?.name
                                featureObj.units = feature?.featureId?.allowedUnits
                                featureObj.type = feature?.type
                                if (feature?.requirements?.range) {
                                    featureObj.range = { min: feature?.requirements?.range?.min, max: feature?.requirements?.range?.max }
                                }
                                else if (feature?.requirements?.isIncremental) {
                                    featureObj.range = { min: feature?.requirements?.isIncremental?.min }
                                }
                                featureObj.changeRate = feature?.requirements?.changeRate
                                featureObj.selectedUnit = feature?.requirements?.unit
                                return featureObj
                            })

                            dispatch(setSelectedFeatures(featuresArr))
                        }
                    }
                })
                .catch(error => console.log(error))
        }
    }, [id])

    const handleSelectChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'vendor') {
            setVendor(prevState => {
                return { ...prevState, selected: value }
            })
            setFormErrors(prevState => {
                return { ...prevState, vendor: "" }
            })
            dispatch(setSelectedFeatures([]))

        }
        if (name === 'deviceModel') {
            setDeviceModel(prevState => {
                return { ...prevState, selected: value }
            })
            const selectedModel = totalDeviceModel?.data?.find(model => {
                return model?._id === value
            })
            const features = selectedModel?.featureIds?.map(feature => {
                return { name: feature?.name, _id: feature?._id, type: feature?.type, units: feature?.allowedUnits, selectedUnit: feature?.allowedUnits?.[0] }
            })
            setSensorQuantity("1")
            dispatch(setSelectedFeatures(features))
            setFormErrors(prevState => {
                return { ...prevState, deviceModel: "" }
            })
        }
    }

    const handleTextChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'sensorId') {
            setSensorId(value)
            setFormErrors(prevState => {
                return { ...prevState, sensorId: '' }
            })
        }
        if (name === 'sensorQuantity') {
            setSensorQuantity(value)
            setFormErrors(prevState => {
                return { ...prevState, sensorQuantity: '' }
            })
        }
        if (name === 'sensorName') {
            setSensorName(value)
            setFormErrors(prevState => {
                return { ...prevState, sensorName: '' }
            })
        }
        if (name === 'location') {
            setLocation(value)
            setFormErrors(prevState => {
                return { ...prevState, location: '' }
            })
        }
        else if (name === 'building') {
            setBuilding(value)
            setFormErrors(prevState => {
                return { ...prevState, building: '' }
            })
        }
        else if (name === 'floor') {
            setFloor(value)
            setFormErrors(prevState => {
                return { ...prevState, floor: '' }
            })
        }
        else if (name === 'zone') {
            setZone(value)
            setFormErrors(prevState => {
                return { ...prevState, zone: '' }
            })
        }
        else if (name === 'subzone') {
            setSubzone(value)
            setFormErrors(prevState => {
                return { ...prevState, subzone: '' }
            })
        }
        else if (name === 'area') {
            setArea(value)
            setFormErrors(prevState => {
                return { ...prevState, area: '' }
            })
        }
    }

    const handleCancelClick = () => {
        setDeviceModel(initialState)
        setVendor(initialState)
        setTotalDeviceModel({ data: [] })
        dispatch(setSelectedFeatures([]))
        history.push('/sensordevices')
    }

    const handleFromTimeChange = (value) => {
        if (value) {
            setFromTimeRange(value?.format('LT'))
        } else {
            setFromTimeRange('')
        }
        setFormErrors({ ...formErrors, fromTimeRange: '', toTimeRange: '' })
    }

    const handleToTimeChange = (value) => {
        if (value) {
            setToTimeRange(value?.format('LT'))
        } else {
            setToTimeRange('')
        }
        setFormErrors({ ...formErrors, fromTimeRange: '', toTimeRange: '' })
    }

    const runValidations = () => {

        //text fields

        if (sensorQuantity?.trim()?.length === 0) {
            errors.sensorQuantity = 'Sensor Quantity cannot be empty'
        } else if (Number(sensorQuantity) < 1) {
            errors.sensorQuantity = 'Invalid Sensor Quantity'
        }
        // if (sensorId?.trim()?.length === 0) {
        //     errors.sensorId = 'Sensor Id cannot be empty'
        // }
        // if (sensorName?.trim()?.length === 0) {
        //     errors.sensorName = 'Sensor Name cannot be empty'
        // }
        if (location?.trim()?.length === 0) {
            errors.location = 'Location cannot be empty'
        }
        if (building?.trim()?.length === 0) {
            errors.building = 'Building cannot be empty'
        }
        // if (floor?.trim()?.length === 0) {
        //     errors.floor = 'Floor cannot be empty'
        // }
        // if (zone?.trim()?.length === 0) {
        //     errors.zone = 'Zone cannot be empty'
        // }
        // if (subzone?.trim()?.length === 0) {
        //     errors.subzone = 'Subzone cannot be empty'
        // }
        // if (area?.trim()?.length === 0) {
        //     errors.area = 'Area cannot be empty'
        // }

        //select fields
        if (vendor?.selected === '') {
            errors.vendor = 'Select a Vendor'
        }
        if (deviceModel?.selected === '') {
            errors.deviceModel = 'Select a Device Model'
        }

        //time fields
        if (!isDataContinuos) {
            if (fromTimeRange === '') {
                errors.fromTimeRange = 'Please select From Time'
            }
            if (toTimeRange === '') {
                errors.toTimeRange = 'Please select To Time'
            }
            if (fromTimeRange !== '' && toTimeRange !== '') {
                const format = 'h:mm a';
                const parsedTime1 = moment(fromTimeRange, format);
                const parsedTime2 = moment(toTimeRange, format);

                if (parsedTime2.isBefore(parsedTime1)) {
                    errors.toTimeRange = 'To time cant be before From time'
                } else if (fromTimeRange === toTimeRange) {
                    errors.toTimeRange = 'To time and From time cannot be same'
                }
            }
        }

        //feature box validations
        if (features?.selected?.length > 0) {
            features?.selected?.forEach(feature => {
                const error = {}
                const type = feature?.type
                if (type === 'incremental') {           //validations for only incremental features
                    if (!feature?.range?.min || feature?.range?.min?.length === 0) {
                        error.minRange = "Please set min value"
                    }
                    if (!feature?.changeRate || feature?.changeRate?.length === 0) {
                        error.changeRate = "Please set step value"
                    }
                } else if (type === 'range') {
                    if (!feature?.range?.min || feature?.range?.min?.length === 0) {
                        error.minRange = "Please set min value"
                    }
                    if (!feature?.range?.max || feature?.range?.max?.length === 0) {
                        error.maxRange = "Please set max value"
                    }
                    if (feature?.range?.max && feature?.range?.min) {
                        if (+feature?.range?.max < +feature?.range?.min) {
                            error.maxRange = "max range more than min"
                        }
                        if (+feature?.range?.max === +feature?.range?.min) {
                            error.maxRange = "max range same as min"
                        }
                    }
                    if (!feature?.changeRate || feature?.changeRate?.length === 0) {
                        error.changeRate = "Please set step value"
                    } else if (feature?.changeRate && feature?.range?.max && feature?.range?.min) {
                        if ((Number(feature?.range?.max) - Number(feature?.range?.min)) < Number(feature?.changeRate)) error.changeRate = "step value cant be more than range difference"
                    }
                }
                if (Object.keys(error).length > 0) {
                    error._id = feature?._id
                    featureErrors.push(error)
                }
            })
        }
    }

    const updatedSensor = async ({ id, formObj }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}sensor/${id}`, formObj)
            if (response?.status === 200) {
                successHandler('sensor updated successfully')
                history.push('/sensordevices')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addSensor = async ({ formObj }) => {
        try {
            const response = await POST(`${process.env.REACT_APP_URL}sensor?quantity=${sensorQuantity}`, formObj)
            if (response?.status === 201) {
                successHandler('sensor added successfully')
                history.push('/sensordevices')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = () => {
        runValidations()

        if (Object.keys(errors)?.length === 0 && Object.keys(featureErrors)?.length === 0) {
            setFormErrors({})

            const formObj = {}
            formObj.tenantReferenceId = selectedTenant?._id
            formObj.isActive = sensorActive
            if (+sensorQuantity === 1) {
                formObj.sensorId = sensorId?.trim()
                formObj.sensorName = sensorName?.trim()
                formObj.locationReferences = {
                    location: location?.trim(),
                    building: building?.trim(),
                    floor: floor?.trim(),
                    zone: zone?.trim(),
                    subzone: subzone?.trim(),
                    area: area?.trim(),
                }
            }
            // formObj.dataFrequency = dataFrequency
            formObj.vendorReferenceId = vendor?.selected
            formObj.deviceModelReferenceId = deviceModel?.selected
            formObj.timeRange = { isContinuous: isDataContinuos }
            if (!isDataContinuos) {
                formObj.timeRange.startTime = fromTimeRange
                formObj.timeRange.endTime = toTimeRange
            }
            formObj.features = features?.selected?.map(feature => {
                const singleFeature = {}
                const requirements = {}

                requirements.unit = feature?.selectedUnit
                requirements.changeRate = +feature?.changeRate
                if (feature?.type === 'range') {
                    requirements.range = { min: +feature?.range?.min, max: +feature?.range?.max }
                } else if (feature?.type === 'incremental') {
                    requirements.isIncremental = { min: +feature?.range?.min }
                }

                if (feature?.type === 'range' || feature?.type === 'incremental') singleFeature.requirements = requirements
                singleFeature.featureId = feature?._id

                return singleFeature
            })
            console.log("🚀 ~ handleSubmit ~ formObj:", formObj)


            if (id) {
                if (sensorDeployed) {
                    swal({
                        title: "This sensor is generating data!",
                        text: "Even after updating, the sensor will continue to generate data with old parameters. Continue?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then(async (confirm) => {
                            try {
                                if (confirm) {
                                    updatedSensor({ id, formObj })
                                }
                            } catch (error) {
                                console.log(error)
                            }

                        })
                } else {
                    updatedSensor({ id, formObj })
                }
            } else {
                addSensor({ formObj })
            }

        } else {
            setFormErrors(errors)
            dispatch(updateFeatureErrors(featureErrors))
        }
    }

    // const formatSecondsToReadable = (seconds) => {
    //     const duration = moment.duration(seconds, 'seconds');

    //     const hours = Math.floor(duration.asHours());
    //     const minutes = duration.minutes();
    //     const remainingSeconds = duration.seconds();

    //     if (hours > 0) {
    //         return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    //     } else if (minutes > 0) {
    //         return `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    //     } else {
    //         return `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    //     }
    // }
    console.log(deviceModel?.data)

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '500px' },
                textAlign: "center",
                p: 2,
                borderRadius: 1,
                backgroundColor: "white"
            }}
            noValidate
            autoComplete="off"
            display='flex'
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexWrap={'wrap'}
        >
            <Box p={1}>
                <Typography textAlign={'center'} variant='h5' mb={2}>{id ? "Edit this" : "Add a"} sensor device</Typography>
            </Box>
            <Box border="0.5px solid grey" borderRadius={'10px'} width={"60%"} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <Typography variant='h6' fontWeight={'bold'} alignSelf={'center'} mt={2}>Data Generation Details</Typography>
                <Divider sx={{ width: "30%", alignSelf: "center", mb: 3 }} />
                <Box width={'100%'} display={'flex'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} pl={1} columnGap={1} >
                    <Box border="0.5px solid #C4C4C4" borderRadius="5px" sx={{ width: "500px", height: "56px", display: 'flex', justifyContent: 'center', alignItems: "center" }} mt={formErrors?.sensorQuantity ? -2 : 0}>
                        <Typography fontWeight={'bold'}>Active Status</Typography>
                        <Switch
                            checked={sensorActive}
                            onChange={() => {
                                setSensorActive(!sensorActive)
                            }}
                            name="status"
                            color='warning'
                        />
                    </Box>
                    <FormControl sx={{ mt: 0.5 }}>
                        <TextField
                            fullWidth={true}
                            error={!!formErrors?.sensorQuantity}
                            label="Sensor Quantity *"
                            value={sensorQuantity}
                            type='number'
                            InputProps={{ inputProps: { min: 1 } }}
                            name="sensorQuantity"
                            onChange={handleTextChange}
                            disabled={!!id}
                        />
                        <FormHelperText error >{formErrors["sensorQuantity"] ? formErrors["sensorQuantity"] : null}</FormHelperText>
                    </FormControl>
                </Box>
                <Box sx={{ width: "100%" }}>

                    <FormControl fullWidth sx={{ maxWidth: "500px", m: 1 }} >
                        <InputLabel
                            error={!!formErrors['vendor']}
                        >Select Vendor *</InputLabel>
                        <Select
                            // labelId={id}
                            fullWidth
                            error={!!formErrors['vendor']}
                            value={vendor?.selected}
                            label={"Select Vendor"}
                            name={'vendor'}
                            onChange={handleSelectChange}
                        >
                            {vendor?.data?.map((ele) => {
                                return (
                                    <MenuItem value={ele._id} key={ele._id}>{ele.name}</MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText error>{formErrors['vendor'] ? formErrors['vendor'] : null}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth sx={{ maxWidth: "500px", m: 1 }} >
                        <InputLabel
                            error={!!formErrors['deviceModel']}
                        >Select device Model *</InputLabel>
                        <Select
                            fullWidth
                            error={!!formErrors['deviceModel']}
                            value={deviceModel?.selected}
                            label={"Select device Model"}
                            name={'deviceModel'}
                            onChange={handleSelectChange}
                        >
                            {deviceModel?.data?.map((ele) => {
                                return (
                                    <MenuItem value={ele._id} key={ele._id}>{ele.name}</MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText error>{formErrors['deviceModel'] ? formErrors['deviceModel'] : null}</FormHelperText>
                    </FormControl>
                </Box>
                <Box p={2} width={"100%"} sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center"
                }}>
                    <Typography
                        fontWeight={'bold'}
                        ml={8}
                        color={isDataContinuos ? "black" : 'grey'}
                        onClick={() => {
                            setIsDataContinuous(!isDataContinuos)
                            setFormErrors({ ...formErrors, fromTimeRange: '', toTimeRange: '' })
                        }}>Continuous Data </Typography>
                    <Checkbox
                        checked={isDataContinuos}
                        onChange={(e) => {
                            setIsDataContinuous(e.target.checked)
                            if (!id) {
                                setFromTimeRange('')
                                setToTimeRange('')
                            }
                            setFormErrors({ ...formErrors, fromTimeRange: '', toTimeRange: '' })
                        }}
                    />
                    <Typography>OR</Typography>

                    <Typography
                        ml={5}

                        fontWeight={'bold'}
                        color={isDataContinuos ? "grey" : "black"}
                        onClick={() => { setIsDataContinuous(false) }}>Time Range</Typography>
                    <Checkbox
                        checked={!isDataContinuos}
                        onChange={(e) => {
                            setIsDataContinuous(!isDataContinuos)
                            if (!id) {
                                setFromTimeRange('')
                                setToTimeRange('')
                            }
                            setFormErrors({ ...formErrors, fromTimeRange: '', toTimeRange: '' })
                        }}
                        mr={2}
                    />
                    <Box display={'flex'} mt={0.5} alignItems={'center'}>
                        <Typography ml={2} mr={5}>From</Typography>
                        <Stack >
                            <TimePicker
                                showSecond={false}
                                className="xxx"
                                value={fromTimeRange ? moment(fromTimeRange, 'h:mm a') : null}
                                onChange={handleFromTimeChange}
                                disabled={isDataContinuos}
                                format={'h:mm a'}
                                placeholder='From'
                                use12Hours
                                inputReadOnly
                            />
                            {formErrors?.fromTimeRange && <Typography color="red" variant='caption'>{formErrors?.fromTimeRange}</Typography>}
                        </Stack>
                    </Box>
                    <Box display={'flex'} mt={0.5} alignItems={'center'}>
                        <Typography ml={5} mr={5}>To</Typography>
                        <Stack  >
                            <TimePicker
                                showSecond={false}
                                // className="xxx"
                                className="timepickrc"
                                onChange={handleToTimeChange}
                                value={toTimeRange ? moment(toTimeRange, 'h:mm a') : null}
                                format={'h:mm a'}
                                disabled={isDataContinuos}
                                placeholder='To'
                                use12Hours
                                inputReadOnly
                            />
                            {formErrors?.toTimeRange && <Typography color="red" variant='caption'>{formErrors?.toTimeRange}</Typography>}
                        </Stack>
                    </Box>
                </Box>
                {/* <Box p={2} width={"100%"} sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center"
                }}>
                    <Typography fontWeight={'bold'} ml={8} mr={2}>Data Frequency -</Typography>

                    <Slider
                        sx={{ maxWidth: '60%' }}
                        // defaultValue={10}
                        valueLabelDisplay="auto"
                        step={1}
                        // marks
                        // size='small'
                        color="warning"
                        min={30}
                        max={3600}
                        value={dataFrequency}
                        onChange={(e) => {
                            setDataFrequency(e.target.value)
                        }}
                    />
                    <Typography ml={4}>({dataFrequency && formatSecondsToReadable(dataFrequency)})</Typography>
                </Box> */}
            </Box>
            {features?.selected?.length > 0 && <Box width={'60%'} p={2} mt={2} border="0.5px solid grey" display={'flex'} flexDirection={'column'} alignItems={'center'} borderRadius={'10px'} >
                <Typography variant='h6' fontWeight={'bold'}>Feature Details</Typography>
                <Divider sx={{ width: "20%" }} />
                <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mt={2} >
                    {features?.selected?.map(feature => {
                        return <FeatureBox key={feature?._id} {...feature} />
                    })}
                </Box>
            </Box>}
            <Box width={'60%'} p={2} mt={2} border="0.5px solid grey" borderRadius={'10px'} display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
                <Box width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mb={3} >
                    <Typography variant='h6' fontWeight={'bold'} >Master Details</Typography>
                    <Divider sx={{ width: "20%" }} />
                </Box>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.sensorId}
                        label="Sensor Id *"
                        value={sensorId}
                        name="sensorId"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error >{formErrors["sensorId"] ? formErrors["sensorId"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.sensorName}
                        label="Sensor Name *"
                        value={sensorName}
                        name="sensorName"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["sensorName"] ? formErrors["sensorName"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.location}
                        label="Location Reference *"
                        value={location}
                        name="location"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["location"] ? formErrors["location"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.building}
                        label="Building Reference *"
                        value={building}
                        name="building"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["building"] ? formErrors["building"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.floor}
                        label="Floor Reference *"
                        value={floor}
                        name="floor"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["floor"] ? formErrors["floor"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.zone}
                        label="Zone Reference *"
                        value={zone}
                        name="zone"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["zone"] ? formErrors["zone"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.subzone}
                        label="Subzone Reference *"
                        value={subzone}
                        name="subzone"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["subzone"] ? formErrors["subzone"] : null}</FormHelperText>
                </FormControl>
                <FormControl >
                    <TextField
                        fullWidth={true}
                        error={!!formErrors?.area}
                        label="Area Reference *"
                        value={area}
                        name="area"
                        onChange={handleTextChange}
                    />
                    <FormHelperText error>{formErrors["area"] ? formErrors["area"] : null}</FormHelperText>
                </FormControl>
            </Box>
            <Box width={'60%'} p={2}>
                <Button
                    sx={{
                        mr: 4,
                        backgroundColor: "#1976D2",
                        color: "white",
                        ':hover': { bgcolor: '#FF4500	', color: 'white', fontWeight: "bold" },
                        width: "200px"
                    }}
                    onClick={handleSubmit}
                >Submit</Button>
                <Button
                    sx={{
                        backgroundColor: "#333333",
                        color: "white",
                        ':hover': { bgcolor: '#333333	', color: 'white', fontWeight: "bold" },
                        width: "200px"
                    }}
                    onClick={handleCancelClick}
                >Cancel</Button>
            </Box>
        </Box >
    )
}

export default SensorDevicesForm