import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Divider, Grid, Box, Typography } from '@mui/material';

const BasicCard = (props) => {
    const { content } = props;
    return (
        <Box sx={{ margin: "100px", p: 1 }}>
            <Card elevation={1} sx={{ borderRadius: '0px', width: "100%", backgroundColor: "#F5F5F5", height: "100%", margin: "0px" }}>
                <Grid p={3} pt={8} pb={8} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#FF6520', height: "32%", minWidth: '22vw' }}>
                    <Box display='flex' justifyContent={'center'} >
                        <Typography fontFamily={'Roboto'} fontSize='5vh' sx={{ color: "white" }}>Simulation Engine</Typography>
                    </Box>
                </Grid>
                <Divider />
                <Grid item >
                    <CardContent sx={{ backgroundColor: '#f5f5f5', height: "100%", margin: "10px" }} >{content}</CardContent>
                </Grid>
                <Divider />
                <Grid item sx={{ backgroundColor: '#4b4b4b' }} color={'white'} fontSize={12}>
                    <Box display={'flex'} width={'100%'} justifyContent={'center'} height="100%" alignItems="center"  >
                        <Box p={0.5} >
                            <Typography fontSize={'1.5vh'}> Have a query, write to us at needhelp@nhance.ai</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Card>
        </Box>
    );
};

export default BasicCard;